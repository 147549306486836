import React                from 'react';
import PropTypes            from 'prop-types';
import Flux                 from '../../../flux/Flux';
import Translations         from '../../../utils/Translations';
import {VXPay, VXPayAction} from '../../../utils/VXPay';

class MediaPin extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state                    = {
			isFavorite: this.props.initialIsFavorite,
		};
		this.onAlbumPinStatus         = this.onAlbumPinStatus.bind(this);
		this.onPicturePinStatus       = this.onPicturePinStatus.bind(this);
		this.onButtonClicked          = this.onButtonClicked.bind(this);
		this.togglePin                = this.togglePin.bind(this);
	}

	componentDidMount() {
		if (this.props.pictureId) {
			Flux.Favorite.addPicturePinStatusListener(this.onPicturePinStatus);
		} else  {
			if(!this.props.isSedcard){
				Flux.Favorite.addAlbumPinStatusListener(this.onAlbumPinStatus);
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.initialIsFavorite !== this.props.initialIsFavorite) {
			this.setState({
				isFavorite: nextProps.initialIsFavorite,
			});
		}
	}

	componentWillUnmount() {
		if (this.props.albumId) {
			if(!this.props.isSedcard) {
				Flux.Favorite.removeAlbumPinStatusListener(this.onAlbumPinStatus);
			}
		} else if (this.props.pictureId) {
			Flux.Favorite.removePicturePinStatusListener(this.onPicturePinStatus);
		} 
	}

	onAlbumPinStatus(albumId, status) {
		if (this.props.albumId && albumId === this.props.albumId) {
			this.setState({
				isFavorite: status,
			});
		}
	}

	onPicturePinStatus(pictureId, status) {
		if (this.props.pictureId && pictureId === this.props.pictureId) {
			this.setState({
				isFavorite: status,
			});
		}
	}

	togglePin() {
		if (this.props.pictureId) {
			if (this.state.isFavorite) {
				Flux.Favorite.unpinPicture(this.props.pictureId);
				const pinType = Flux.Constants.TileGrid.PINBOARD_PHOTOS;
				const payload = Flux.TileGrid.getPinBoardPayload(pinType);
				if (Flux.TileGrid.hasGridData(pinType, payload)) {
					Flux.TileGrid.removePinnedTileElement(pinType, payload, this.props.parentTileIndex);
				}
			} else {
				Flux.Favorite.pinPicture(this.props.pictureId);
			}

		} else {
			if(this.props.isSedcard) {
				if (this.props.isSedcardPinned) {
					Flux.Favorite.unpinSedcards(this.props.actorId);
					const pinType = Flux.Constants.TileGrid.PINBOARD_ALBUMS;
					const payload = Flux.TileGrid.getPinBoardPayload(pinType);
					if (Flux.TileGrid.hasGridData(pinType, payload)) {
						Flux.TileGrid.removePinnedTileElement(pinType, payload, this.props.parentTileIndex);
					}
				} else {
					Flux.Favorite.pinSedcards(this.props.actorId);
				}
			} else {
				if (this.state.isFavorite) {
					let pinType = null;
					Flux.Favorite.unpinAlbum(this.props.albumId);
					if (this.props.mediaType === 'video') {
						pinType = Flux.Constants.TileGrid.PINBOARD_VIDEOS;
					} else {
						pinType = Flux.Constants.TileGrid.PINBOARD_ALBUMS;
					}
					if (pinType) {
						const payload = Flux.TileGrid.getPinBoardPayload(pinType);
						if (Flux.TileGrid.hasGridData(pinType, payload)) {
							Flux.TileGrid.removePinnedTileElement(pinType, payload, this.props.parentTileIndex);
						}
					}
				} else {
					Flux.Favorite.pinAlbum(this.props.albumId);
				}
			}
		}

		this.setState({
			isFavorite: !this.state.isFavorite,
		});
	}

	onButtonClicked(e) {
		if (!Flux.Guest.isLoggedIn()) {
			let actionType, targetId;

			if (this.props.albumId) {
				actionType = Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_ALBUM;
				targetId   = this.props.albumId;
			} else if (this.props.pictureId) {
				actionType = Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_PICTURE;
				targetId   = this.props.pictureId;
			} else if (this.props.actorId) {
				actionType = Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_SEDCARDS;
				targetId   = this.props.actorId;
			}

			const action = new VXPayAction(actionType, {
				targetId: targetId,
			});
			VXPay.openSignupOrLogin({}, action);
		} else if (typeof this.props.onClickCallback === 'function') {
			this.props.onClickCallback();
		} else {
			this.togglePin();
		}
		if (e.preventDefault) {
			e.preventDefault();
		}

        if (typeof this.props.pinOnClickFn === 'function') {
            this.props.pinOnClickFn();
        }

	}

	render() {
		let classes        = '';
		let title          = '';
		let wrapperClasses = 'favorite-media-pin h-cursor-pointer';

		if ((!this.props.isSedcard && this.state.isFavorite) || (this.props.isSedcard && this.props.isSedcardPinned)) {
			wrapperClasses += ' is-active';
			classes = 'icon -icon-pin-full';
			title   = Translations.get('FavoriteMediaRemove');
		} else {
			classes = 'icon -icon-pin-line';
			title   = Translations.get('FavoriteMediaAdd');
		}
		if (this.props.fontSize) {
			classes = classes + ' ' + this.props.fontSize;
		}

		if (!this.props.tooltipEnabled) {
			title = null;
		}

		const label = <i data-tooltip-content={title} className={classes} data-tooltip-id={this.props.tooltipPlacement === "top" ? "tooltip" : "tooltip-bottom"} />;

		return (
			<span className={wrapperClasses} onClick={this.onButtonClicked}>{label}</span>
		);
	}

}

MediaPin.propTypes = {
	actorId:           PropTypes.number,
	albumId:           PropTypes.number,
	pictureId:         PropTypes.number,
	initialIsFavorite: PropTypes.bool.isRequired,
	isSedcard:         PropTypes.bool,
	isSedcardPinned:   PropTypes.bool,
	onClickCallback:   PropTypes.func,
    pinOnClickFn:      PropTypes.func,
	fontSize:          PropTypes.string,
	tooltipPlacement:  PropTypes.string,
	parentTileIndex:   PropTypes.number,
	mediaType:         PropTypes.string,
	tooltipEnabled:    PropTypes.bool,
};

MediaPin.defaultProps = {
	tooltipPlacement: 'bottom',
	tooltipEnabled:   true,
};

export default MediaPin;
